import logo from "./logo.svg";
import imgpc from "./resources/img/site-pc.png";
import imgmobile from "./resources/img/site-mobile.png";
import imgfacebook from "./resources/img/facebooklogo.png";
import imginstagram from "./resources/img/instagramlogo.png";
import imgmaps from "./resources/img/mapslogo.png";
import backgroundbutton from "./resources/img/fond-bouton.png";
import backgroundbutton2 from "./resources/img/fond-bouton-2.png";
import "./App.css";
import "./resources/fonts/BebasNeue-Regular.ttf";

function App() {
  return (
    <div className="App">
      <img className="pc-img" src={imgpc}></img>
      <img className="mobile-img" src={imgmobile}></img>

      <div id="lignebouton">
        <div>
          <img className="instagram-img" src={imginstagram}></img>
          <div
            id="instagramlink"
            className="button-phoenix"
            style={{ backgroundImage: `url(${backgroundbutton})` }}
          >
            <a href="https://www.instagram.com/lerepaireduphoenix/">
              &#62;Nous suivre&#60;
            </a>
          </div>
        </div>
        <div>
          <img className="facebook-img" src={imgfacebook}></img>
          <div
            id="facebooklink"
            className="button-phoenix"
            style={{ backgroundImage: `url(${backgroundbutton2})` }}
          >
            <a href="https://www.facebook.com/profile.php?id=61550928641609">
              &#62;Nous suivre&#60;
            </a>
          </div>
        </div>
        <div>
          <img className="googlemaps-img" src={imgmaps}></img>
          <div
            id="googlemapslink"
            className="button-phoenix"
            style={{ backgroundImage: `url(${backgroundbutton})` }}
          >
            <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi486CR_I2HAxVsVaQEHSupC0sQ4kB6BAgyEAM&url=%2Fmaps%2Fplace%2F%2Fdata%3D!4m2!3m1!1s0x12ac0dbbf79a0105%3A0xee1df490f5e597d7%3Fsa%3DX%26ved%3D1t%3A8290%26ictx%3D111&usg=AOvVaw3JGOJzZ668z9i5G2hvePwE&opi=89978449">
              &#62;Nous trouver&#60;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
